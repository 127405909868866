import type { TFunction } from 'i18next';

const countOfItems = 9;

const getArrayByKey = (t: TFunction, key: string) =>
  t(key, {
    returnObjects: true,
    defaultValue: [],
  }) as unknown as string[];

export const policyItems = (t: TFunction) =>
  Array.from({ length: countOfItems })
    .map((_, idx) => idx + 1)
    .map((index) => ({
      index,
      title: t(`policy.block_${index}.title`),
      caption: t(`policy.block_${index}.caption`, { defaultValue: '' }),
      list: getArrayByKey(t, `policy.block_${index}.list`),
      descriptions: getArrayByKey(t, `policy.block_${index}.descriptions`),
    }));
