import type { PropsWithChildren } from 'react';
import { useRef, useEffect } from 'react';
import { Fancybox as NativeFancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';

interface FancyboxProps extends PropsWithChildren<Partial<NativeFancybox>> {
  delegate?: string;
  className?: string;
}

export const Fancybox = ({ children, className, ...props }: FancyboxProps) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    const delegate = props.delegate || '[data-fancybox]';
    const options = props.options || {};

    NativeFancybox.bind(container, delegate, options);

    return () => {
      NativeFancybox.unbind(container);
      NativeFancybox.close();
    };
  });

  return (
    <div className={className} ref={containerRef}>
      {children}
    </div>
  );
};
