import { useTranslation } from 'react-i18next';
import { Container } from '@/shared/ui/container';
import { Section } from '@/shared/ui/section';
import { Typography } from '@/shared/ui/typography';
import { galleryItems } from './config/gallery.config';
import styles from './styles.module.scss';
import { Fancybox } from '@/shared/ui/fancybox';

export const Gallery = () => {
  const { t } = useTranslation();

  return (
    <Section className={styles.section}>
      <Container className={styles.container}>
        <Typography visuallyHidden className={styles.title} tag="h2">
          {t('gallery.title')}
        </Typography>
        <Fancybox className={styles.grid}>
          {galleryItems.map((item) => (
            <a
              key={item.index}
              className={styles.item}
              data-fancybox="gallery"
              href={item.previewUrl}
            >
              <img className={styles.image} src={item.previewUrl} alt="" />
            </a>
          ))}
        </Fancybox>
      </Container>
    </Section>
  );
};
