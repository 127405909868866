import type { RoutePath } from '@/shared/context/router.context';
import { useRouter } from '@/shared/context/router.context';
import type { AnchorHTMLAttributes, MouseEventHandler } from 'react';

interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  href: RoutePath;
  scrollToObject?: boolean;
}

export const Link = ({
  href,
  onClick,
  children,
  scrollToObject,
  ...rest
}: LinkProps) => {
  const router = useRouter();

  const handleLink: MouseEventHandler<HTMLAnchorElement> = (e) => {
    onClick?.(e);

    e.preventDefault();

    if (scrollToObject) {
      const [_, anchor] = href.split('#');

      const section = document.getElementById(anchor);

      section?.scrollIntoView({ block: 'start', behavior: 'smooth' });
    } else {
      window.scrollTo(0, 0);
    }

    router.navigate(href);
  };

  return (
    <a href={href} onClick={handleLink} {...rest}>
      {children}
    </a>
  );
};
