/* eslint-disable jsx-a11y/label-has-associated-control */
import clsx from 'clsx';
import IntlTelInput from 'intl-tel-input/reactWithUtils';
import type { FormEventHandler } from 'react';
import { useEffect, useRef, useState } from 'react';
import { Button } from '@/shared/ui/button';
import { Link } from '@/shared/ui/link';
import { Trans, useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { Icon } from '@/shared/ui/icon';
import type { IntlTelInputRef } from 'intl-tel-input/react';

interface ContactFormProps {
  className?: string;
  notificationClassName?: string;
  onSubmit?: () => void;
}

export const ContactForm = ({
  className,
  notificationClassName,
  onSubmit,
}: ContactFormProps) => {
  const { t } = useTranslation();

  const ref = useRef<IntlTelInputRef>(null);

  const [name, setName] = useState('');
  const [number, setNumber] = useState('');
  const [email, setEmail] = useState('');
  const [text, setText] = useState('');
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  const [isShowNotification, setIsShowNotification] = useState(false);

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);

    const xhr = new XMLHttpRequest();

    ref.current?.getInstance()?.setNumber('');
    setName('');
    setNumber('');
    setEmail('');
    setText('');
    setIsShowNotification(true);

    xhr.open('POST', 'mail.php', true);
    xhr.send(formData);

    onSubmit?.();
  };

  useEffect(() => {
    if (isShowNotification) {
      setTimeout(() => {
        setIsShowNotification(false);
      }, 6000);
    }
  }, [isShowNotification]);

  return (
    <form className={clsx(styles.form, className)} onSubmit={handleSubmit}>
      <div className={styles.fields}>
        <input
          value={name}
          onChange={(e) => setName(e.target.value)}
          name="Имя"
          placeholder={t('contacts.inputs_placeholders.name')}
          className={styles.input}
          required
        />
        <IntlTelInput
          ref={ref}
          onChangeNumber={setNumber}
          initOptions={{
            initialCountry: 'auto',
            strictMode: true,
            geoIpLookup: (callback) => {
              fetch('https://ipapi.co/json')
                .then((res) => res.json())
                .then((data) => {
                  callback(data.country_code);
                })
                .catch(() => callback('us'));
            },
          }}
        />
        <input name="Номер телефона" type="hidden" value={number} />
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          name="Email"
          placeholder={t('contacts.inputs_placeholders.email')}
          className={styles.input}
          required
        />
        <textarea
          value={text}
          onChange={(e) => setText(e.target.value)}
          name="Text"
          placeholder={t('contacts.inputs_placeholders.text')}
          className={clsx(styles.input, styles.textarea)}
          required
        />
        <label className={styles.checkbox}>
          <input
            type="checkbox"
            required
            checked={isTermsAccepted}
            onChange={() => setIsTermsAccepted((prev) => !prev)}
          />
          <div className={styles.checkboxWrapper}>
            <span>
              <Trans
                t={t}
                i18nKey="contacts.privacy_policy"
                components={[<Link key={0} href="/policy" />]}
              />
            </span>
          </div>
        </label>
      </div>
      <Button className={styles.btn}>{t('send')}</Button>
      <div
        className={clsx(
          styles.notification,
          {
            [styles.showNotification]: isShowNotification,
          },
          notificationClassName,
        )}
      >
        <Icon className={styles.notificationIcon} name="common/success" />
        <span className={styles.notificationText}>{t('form_success')}</span>
        <button
          onClick={() => setIsShowNotification(false)}
          type="button"
          className={styles.notificationClose}
        >
          <Icon name="common/close" />
        </button>
      </div>
    </form>
  );
};
