import { ReactComponent as BannerEnImage } from '../assets/banner_ro.svg';
import { ReactComponent as BannerRoImage } from '../assets/banner_en.svg';

export const banners: Record<string, typeof BannerRoImage> = {
  ro: BannerRoImage,
  en: BannerEnImage,
  it: BannerEnImage,
  bg: BannerEnImage,
  sl: BannerEnImage,
};
