import type { TFunction } from 'i18next';

const countOfItems = 3;

export const reviewsItems = (t: TFunction) =>
  Array.from({ length: countOfItems })
    .map((_, idx) => idx + 1)
    .map((index) => ({
      avatarUrl: `/images/reviews/avatar${index}.png`,
      name: t(`reviews.item_${index}.name`),
      company: t(`reviews.item_${index}.company`),
      review: t(`reviews.item_${index}.text`),
    }));
