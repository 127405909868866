import { useTranslation } from 'react-i18next';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ScrollProvider } from '@/shared/context/scroll.context';
import { Header } from '@/widgets/header';
import { Footer } from '@/widgets/footer';
import { Pages } from '@/pages';
import { RouterProvider } from '@/shared/context/router.context';

export const App = () => {
  const { t } = useTranslation();

  return (
    <HelmetProvider>
      <ScrollProvider>
        <RouterProvider>
          <Helmet>
            <title>{t('meta.title')}</title>
            <meta name="description" content={t('meta.description')} />
          </Helmet>
          <Header />
          <main className="main">
            <Pages />
          </main>
          <Footer />
        </RouterProvider>
      </ScrollProvider>
    </HelmetProvider>
  );
};
