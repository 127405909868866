import { useTranslation } from 'react-i18next';
import { Container } from '@/shared/ui/container';
import { Typography } from '@/shared/ui/typography';
import { banners } from './config/banners.config';
import { Button } from '@/shared/ui/button';
import styles from './styles.module.scss';
import { ContactModal } from '../contact-modal';
import { useState } from 'react';

export const Hero = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const BannerImage = banners[language];

  return (
    <section className={styles.root}>
      <Container className={styles.container}>
        <div className={styles.content}>
          <Typography
            className={styles.title}
            tag="h1"
            dangerouslySetInnerHTML={{ __html: t('hero.title') }}
          />
          <p className={styles.desc}>{t('hero.description')}</p>
          <Button onClick={() => setIsModalOpen(true)} className={styles.btn}>
            {t('contact_us')}
          </Button>
        </div>
      </Container>
      <BannerImage className={styles.banner} />
      <ContactModal
        isOpened={isModalOpen}
        close={() => setIsModalOpen(false)}
      />
    </section>
  );
};
