import type { IconName } from '../../icon';

interface SocialItem {
  href: string;
  icon: IconName;
}

export const socialItems: SocialItem[] = [
  {
    href: 'skype:live:.cid.b58c469d1a483e36?chat',
    icon: 'social/skype',
  },
  {
    href: 'https://t.me/ar_sales',
    icon: 'social/telegram',
  },
  {
    href: 'https://wa.me/48451679505',
    icon: 'social/whatsapp',
  },
  {
    href: 'mailto:bdm.artur@abx-play.com',
    icon: 'social/email',
  },
];
