/* eslint-disable react/no-array-index-key */
import { useTranslation } from 'react-i18next';
import { policyItems } from './config/policy.config';
import { Container } from '@/shared/ui/container';
import { Typography } from '@/shared/ui/typography';
import styles from './styles.module.scss';

export const Policy = () => {
  const { t } = useTranslation();

  const items = policyItems(t);

  return (
    <section className={styles.section}>
      <Container className={styles.container}>
        {items.map((item, idx) => (
          <div key={item.index} className={styles.item}>
            <Typography className={styles.title} tag={idx === 0 ? 'h2' : 'h4'}>
              {item.title}
            </Typography>
            <div className={styles.descriptions}>
              {item.descriptions.map((description, idx) => (
                <p
                  className={styles.desc}
                  key={idx}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              ))}
            </div>
            {item.list.length > 0 && (
              <ul className={styles.itemList}>
                {item.list.map((listItem, idx) => (
                  <li className={styles.listItem} key={idx}>
                    {listItem}
                  </li>
                ))}
              </ul>
            )}
            {item.caption ? (
              <p
                className={styles.desc}
                dangerouslySetInnerHTML={{ __html: item.caption }}
              />
            ) : null}
          </div>
        ))}
      </Container>
    </section>
  );
};
