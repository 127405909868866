import { RouterContext, type RoutePath } from '@/shared/context/router.context';
import { useContext, useEffect, type JSX } from 'react';
import { Policy } from './policy';
import { Home } from './home/home';

const pages: Record<RoutePath, () => JSX.Element> = {
  '/': Home,
  '/policy': Policy,
};

export const Pages = () => {
  const router = useContext(RouterContext);

  const Component = pages[router.route] ?? Home;

  useEffect(() => {
    if (window.location.hash) {
      const section = document?.querySelector(window.location.hash);

      section?.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }, [router.route]);

  return <Component />;
};
