import type { HTMLAttributes } from 'react';
import styles from './styles.module.scss';
import clsx from 'clsx';
import { Link } from '../link';

type LogoProps = HTMLAttributes<HTMLAnchorElement>;

export const Logo = ({ className, ...rest }: LogoProps) => {
  return (
    <Link href="/" className={clsx(styles.logo, className)} {...rest}>
      <img className={styles.img} src="/images/logo.png" alt="CLICKoBET" />
    </Link>
  );
};
