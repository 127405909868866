import { Hero } from '@/widgets/hero';
import { Benefits } from '@/widgets/benefits';
import { Offer } from '@/widgets/offer';
import { WayWorking } from '@/widgets/way-working';
import { HowItWorks } from '@/widgets/how-it-works';
import { Gifts } from '@/widgets/gifts';
import { Gallery } from '@/widgets/gallery';
import { Certificate } from '@/widgets/certificate';
import { Steps } from '@/widgets/steps';
import { Terms } from '@/widgets/terms';
import { Reviews } from '@/widgets/reviews';
import { Contacts } from '@/widgets/contacts';

export const Home = () => {
  return (
    <>
      <Hero />
      <Benefits />
      <Offer />
      <Certificate />
      <Steps />
      <Gallery />
      <Terms />
      <Reviews />
      <WayWorking />
      <HowItWorks />
      <Gifts />
      <Contacts />
    </>
  );
};
