import { useTranslation } from 'react-i18next';
import { Container } from '@/shared/ui/container';
import { Section } from '@/shared/ui/section';
import { Typography } from '@/shared/ui/typography';
import { ParallaxImage } from '@/shared/ui/parallax-image';
import { wayWorkingItems } from './config/way-working-items.config';
import styles from './styles.module.scss';
import { WorkItem } from './ui/work-item';

export const WayWorking = () => {
  const { t } = useTranslation();

  const items = wayWorkingItems(t);

  return (
    <Section className={styles.section}>
      <Container className={styles.container}>
        <Typography className={styles.title} tag="h2">
          {t('way_of_working.title')}
        </Typography>
        <ul className={styles.list}>
          {items.map((item) => (
            <WorkItem key={item.description} item={item} />
          ))}
        </ul>
        <ParallaxImage src="coins/1" className={styles.coin} />
      </Container>
    </Section>
  );
};
