import { useContext, useEffect, useRef, useState } from 'react';
import type { CSSProperties, HTMLAttributes } from 'react';
import { ScrollContext } from '@/shared/context/scroll.context';
import clsx from 'clsx';
import styles from './styles.module.scss';

interface ImageProps extends HTMLAttributes<HTMLImageElement> {
  src: string;
  allowParalax?: boolean;
  paralaxFactor?: number;
  imageType?: 'png' | 'svg';
  alt?: string;
}

export const ParallaxImage = ({
  src,
  imageType = 'png',
  className,
  paralaxFactor = 0.015,
  allowParalax = true,
  alt = 'ParallaxImage',
  ...rest
}: ImageProps) => {
  const [newScrollY, setNewScrollY] = useState<number>(0);
  const { scrollY } = useContext(ScrollContext);
  const ref = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const section = ref.current?.closest('section');

    const offsetTop = section?.offsetTop ?? 0;

    setNewScrollY(scrollY - offsetTop);
  }, [scrollY]);

  const parallaxStyle = `${newScrollY * paralaxFactor}px`;

  return (
    <img
      ref={ref}
      aria-hidden
      className={clsx(styles.root, className, {
        [styles.allowParalax]: allowParalax,
      })}
      loading="lazy"
      src={`/images/${src}.${imageType}`}
      alt={alt}
      style={{ '--transform': parallaxStyle } as CSSProperties}
      {...rest}
    />
  );
};
