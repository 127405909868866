import { Modal, type ModalProps } from '@/shared/ui/modal';
import { ContactForm } from '../contact-form';
import { Typography } from '@/shared/ui/typography';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

export const ContactModal = (props: ModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal className={styles.modal} {...props}>
      <div className={styles.container}>
        <Typography tag="h3" className={styles.title}>
          {t('contact_form')}
        </Typography>
        <ContactForm
          notificationClassName={styles.notification}
          className={styles.form}
        />
      </div>
    </Modal>
  );
};
