import { Container } from '@/shared/ui/container';
import { Logo } from '@/shared/ui/logo';
import styles from './styles.module.scss';
import { Link } from '@/shared/ui/link';
import { useTranslation } from 'react-i18next';

export const Footer = () => {
  const { t } = useTranslation();

  const currentYear = new Date().getFullYear();

  return (
    <footer className={styles.root}>
      <Container className={styles.container}>
        <Logo className={styles.logo} />
        <Link className={styles.link} href="/policy">
          {t('privacy_policy_link')}
        </Link>
        <span className={styles.copy}>© {currentYear}</span>
      </Container>
    </footer>
  );
};
