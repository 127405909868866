import { menuItems } from './config/menu-items.config';
import { useTranslation } from 'react-i18next';
import { ParallaxImage } from '@/shared/ui/parallax-image';
import { Link } from '@/shared/ui/link';
import type { RoutePath } from '@/shared/context/router.context';
import type { ModalProps } from '@/shared/ui/modal';
import { Modal } from '@/shared/ui/modal';
import styles from './styles.module.scss';

const countOfItems = 9;

export const menuDecorItems = Array.from({ length: countOfItems }).map(
  (_, idx) => idx + 1,
);

export const Menu = (props: ModalProps) => {
  const { t } = useTranslation();

  const items = menuItems(t);

  return (
    <Modal className={styles.menu} {...props}>
      <ul className={styles.list}>
        {items.map((item) => (
          <li key={item.text} className={styles.item}>
            <Link
              scrollToObject
              onClick={props.close}
              className={styles.link}
              href={item.href as RoutePath}
            >
              {item.text}
            </Link>
          </li>
        ))}
      </ul>
      {menuDecorItems.map((index) => (
        <ParallaxImage
          key={index}
          className={styles[`decor${index}`]}
          src={`menu/${index}`}
        />
      ))}
    </Modal>
  );
};
