/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react';
import { Navigation } from 'swiper/modules';
import { Section } from '@/shared/ui/section';
import { Container } from '@/shared/ui/container';
import { Typography } from '@/shared/ui/typography';
import { Swiper, SwiperSlide } from 'swiper/react';
import { type Swiper as SwiperClass } from 'swiper/types';
import { useTranslation } from 'react-i18next';
import { reviewsItems } from './config/reviews.config';
import 'swiper/css';
import styles from './styles.module.scss';
import { ParallaxImage } from '@/shared/ui/parallax-image';

export const Reviews = () => {
  const { t } = useTranslation();
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const items = reviewsItems(t);

  useEffect(() => {
    swiper?.on('slideChange', () => {
      setIsEnd(swiper.isEnd);
      setIsBeginning(swiper.isBeginning);
    });
  }, [swiper]);

  const isSliderSwipeable = swiper?.allowSlideNext || swiper?.allowSlidePrev;

  return (
    <Section className={styles.section}>
      <Container className={styles.container}>
        <div className={styles.top}>
          <Typography className={styles.title} tag="h2">
            {t('reviews.title')}
          </Typography>
          {isSliderSwipeable ? (
            <div className={styles.btns}>
              <button
                className={styles.prev}
                disabled={isBeginning}
                onClick={() => swiper?.slidePrev()}
                aria-label="Prev"
              />
              <button
                className={styles.next}
                disabled={isEnd}
                onClick={() => swiper?.slideNext()}
                aria-label="Next"
              />
            </div>
          ) : null}
        </div>
        <Swiper
          className={styles.carousel}
          spaceBetween={16}
          slidesPerView="auto"
          breakpoints={{
            '1025': {
              spaceBetween: 24,
              slidesPerView: 3,
            },
          }}
          modules={[Navigation]}
          navigation
          onSwiper={setSwiper}
        >
          {items.map((item, idx) => (
            <SwiperSlide className={styles.slide} key={idx}>
              <div className={styles.item}>
                <div className={styles.itemTop}>
                  <img
                    className={styles.itemAvatar}
                    src={item.avatarUrl}
                    alt={item.name}
                    loading="lazy"
                  />
                  <div className={styles.itemText}>
                    <span className={styles.itemName}>{item.name}</span>
                  </div>
                </div>
                <p className={styles.itemReview}>{item.review}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <ParallaxImage src="coins/1" className={styles.coin} />
      </Container>
    </Section>
  );
};
