import { ReactComponent as CpuSettingIcon } from '../assets/cpu-setting.svg';
import { ReactComponent as ReceiptItem } from '../assets/receipt-item.svg';
import { ReactComponent as NoteFavoriteIcon } from '../assets/note-favorite.svg';
import type { TFunction } from 'i18next';

export const termsItems = (t: TFunction) => [
  {
    icon: CpuSettingIcon,
    label: t('terms.item_1'),
  },
  {
    icon: ReceiptItem,
    label: t('terms.item_2'),
  },
  {
    icon: NoteFavoriteIcon,
    label: t('terms.item_3'),
  },
];
