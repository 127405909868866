import clsx from 'clsx';
import {
  useEffect,
  useState,
  type CSSProperties,
  type HTMLAttributes,
} from 'react';
import { useLockedBody } from './lib/use-locked-body.lib';
import { useEscape } from './lib/use-escape.lib';
import { useWindowSize } from './lib/use-window-size.lib';
import styles from './styles.module.scss';
import { Icon } from '../icon';
import { Portal } from '../portal';

export interface ModalProps extends HTMLAttributes<HTMLDivElement> {
  isOpened: boolean;
  close: () => void;
}

export const Modal = ({
  isOpened,
  close,
  children,
  className,
  ...rest
}: ModalProps) => {
  const [isMounted, setIsMounted] = useState(isOpened);

  const windowSize = useWindowSize();

  useLockedBody(isMounted);

  const handleClose = () => {
    setIsMounted(false);
    setTimeout(() => {
      close();
    }, 200);
  };

  useEscape(handleClose);

  useEffect(() => {
    setIsMounted(isOpened);
  }, [isOpened]);

  return (
    <Portal rootId="#modal">
      {isOpened ? (
        <div
          style={{ '--vh': `${windowSize.height}px` } as CSSProperties}
          className={clsx(
            styles.modal,
            isMounted ? styles.isMounted : styles.isUnmounted,
            className,
          )}
          {...rest}
        >
          <button onClick={handleClose} className={styles.close}>
            <Icon name="common/close" />
          </button>
          {children}
        </div>
      ) : null}
    </Portal>
  );
};
