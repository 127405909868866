import { Section } from '@/shared/ui/section';
import { Container } from '@/shared/ui/container';
import { useTranslation } from 'react-i18next';
import { Button } from '@/shared/ui/button';
import { ParallaxImage } from '@/shared/ui/parallax-image';
import { ContactModal } from '../contact-modal';
import { useState } from 'react';
import styles from './styles.module.scss';

export const Certificate = () => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Section className={styles.section}>
      <Container className={styles.container}>
        <div className={styles.left}>
          <h1
            className={styles.title}
            dangerouslySetInnerHTML={{ __html: t('certificate.title') }}
          />
          <Button onClick={() => setIsModalOpen(true)} className={styles.btn}>
            {t('leave_request')}
          </Button>
        </div>
        <div className={styles.logo}>
          <img
            src="/images/certificate/logo.png"
            alt="Gaming Associates"
            loading="lazy"
          />
        </div>
        <ParallaxImage src="coins/1" className={styles.coin} />
      </Container>
      <ContactModal
        isOpened={isModalOpen}
        close={() => setIsModalOpen(false)}
      />
    </Section>
  );
};
