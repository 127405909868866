import { Section } from '@/shared/ui/section';
import { Container } from '@/shared/ui/container';
import { Typography } from '@/shared/ui/typography';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { termsItems } from './config/terms.config';
import { ParallaxImage } from '@/shared/ui/parallax-image';

export const Terms = () => {
  const { t } = useTranslation();

  const items = termsItems(t);

  return (
    <Section className={styles.section}>
      <Container className={styles.container}>
        <Typography className={styles.title} tag="h2">
          {t('terms.title')}
        </Typography>
        <ul className={styles.list}>
          {items.map((item) => {
            const Icon = item.icon;

            return (
              <li key={item.label} className={styles.item}>
                <div className={styles.icon}>
                  <Icon />
                </div>
                <span
                  className={styles.label}
                  dangerouslySetInnerHTML={{ __html: item.label }}
                />
              </li>
            );
          })}
        </ul>
      </Container>
      <ParallaxImage className={styles.diamond} src="diamonds/2" />
    </Section>
  );
};
