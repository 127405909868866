import styles from './styles.module.scss';
import clsx from 'clsx';
import { Typography } from '@/shared/ui/typography';
import { useState, type CSSProperties } from 'react';
import { useElementOnScreen } from '@/shared/lib/use-element-on-screen.lib';
import type { BaseGiftItem } from '../../config/gifts-items.config';
import type { IconName } from '@/shared/ui/icon';
import { Icon } from '@/shared/ui/icon';
import { Button } from '@/shared/ui/button';
import { useTranslation } from 'react-i18next';
import { ContactModal } from '@/widgets/contact-modal';

interface GiftItemProps {
  item: BaseGiftItem;
}

export const GiftItem = ({ item }: GiftItemProps) => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [itemRef, isVisible] = useElementOnScreen({
    threshold: 0.1,
  });

  return (
    <div
      ref={itemRef}
      style={{ '--flex-direction': item.flexDirection } as CSSProperties}
      className={clsx(styles.item, styles[`item${item.index}`], {
        [styles.isVisible]: isVisible,
      })}
    >
      <div className={styles.text}>
        <Typography
          tag="h2"
          className={clsx(
            styles.title,
            item.isLastElement ? styles.yellowTitle : null,
          )}
          dangerouslySetInnerHTML={{ __html: item.title }}
        />
        <Typography
          tag="p"
          className={styles.desc}
          dangerouslySetInnerHTML={{ __html: item.description }}
        />
        {item.isLastElement ? (
          <Button onClick={() => setIsModalOpen(true)} className={styles.btn}>
            {t('start_now')}
          </Button>
        ) : null}
      </div>
      <div className={styles.imageWrapper}>
        <img className={styles.image} src={item.image} alt={item.title} />
        <Icon
          width={394}
          height={440}
          name={item.puddleImage as IconName}
          className={styles.puddleImage}
        />
      </div>
      <ContactModal
        isOpened={isModalOpen}
        close={() => setIsModalOpen(false)}
      />
    </div>
  );
};
