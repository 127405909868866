import { Section } from '@/shared/ui/section';
import { Container } from '@/shared/ui/container';
import { Typography } from '@/shared/ui/typography';
import { useTranslation } from 'react-i18next';
import { offerItems } from './config/offer.config';
import styles from './styles.module.scss';

export const Offer = () => {
  const { t } = useTranslation();

  const items = offerItems(t);

  return (
    <Section className={styles.section}>
      <Container className={styles.container}>
        <Typography className={styles.title} tag="h2">
          {t('offer.title')}
        </Typography>
        <ul className={styles.list}>
          {items.map(({ Icon, label }) => (
            <li className={styles.item} key={label}>
              <div className={styles.icon}>
                <Icon />
              </div>
              <span className={styles.label}>{label}</span>
            </li>
          ))}
        </ul>
      </Container>
    </Section>
  );
};
