import { Section } from '@/shared/ui/section';
import { Container } from '@/shared/ui/container';
import { useTranslation } from 'react-i18next';
import { Typography } from '@/shared/ui/typography';
import { contactsItems } from './config/contacts.config';
import { ContactForm } from '../contact-form';
import styles from './styles.module.scss';
import clsx from 'clsx';

export const Contacts = () => {
  const { t } = useTranslation();

  const items = contactsItems(t);

  return (
    <Section className={styles.section}>
      <Container className={styles.container}>
        <ContactForm />
        <div className={styles.right}>
          <Typography className={styles.title} tag="h2">
            {t('contacts.title')}
          </Typography>
          <div className={styles.items}>
            {items.map((item) => (
              <div
                key={item.label}
                className={clsx(styles.item, styles[item.align])}
              >
                <span className={styles.label}>{item.label}</span>
                <span className={styles.value}>{item.value}</span>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </Section>
  );
};
