import type { PropsWithChildren } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';

interface Router {
  route: RoutePath;
  navigate: (path: RoutePath) => void;
}

const initialValue: Router = { route: '/', navigate: () => {} };

export const RouterContext = createContext<Router>(initialValue);

export type RoutePath = '/' | '/policy';

export const RouterProvider = ({ children }: PropsWithChildren) => {
  const [route, setRoute] = useState(window.location.pathname);

  useEffect(() => {
    const handlePopState = () => {
      setRoute(window.location.pathname);
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const navigate = (path: RoutePath) => {
    const [pagePath] = path.split('#');

    window.history.pushState({}, '', path);

    setRoute(pagePath);
  };

  return (
    <RouterContext.Provider value={{ route, navigate } as Router}>
      {children}
    </RouterContext.Provider>
  );
};

export const useRouter = () => useContext(RouterContext);
